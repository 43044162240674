import React from 'react';

import DustproofImg from '../assets/complements/dustproof.jpg';
import Mezzaluna from '../assets/complements/mezzaluna.jpg';
import SmoothWheel from '../assets/complements/smooth-wheel.jpg';
import AdjustableWheel from '../assets/complements/adjustable-wheel.jpg';
import DoubleWheel from '../assets/complements/double-wheel.jpg';
import {EyeIcon, ShoppingCart} from "lucide-react";

interface Product {
    id: number;
    name: string;
    asin: string;
    image: string;
    price: number;
}

const products: Product[] = [
    {
        id: 1,
        name: 'Dustproof Sheeter Cover Protects your sheeter from dust',
        asin: 'B0CSZ9HQ2C',
        image: DustproofImg,
        price: 28,
    },
    {
        id: 2,
        name: 'La Gondola Homemade Ravioli Stamp - Mezzaluna Shape',
        asin: 'B01C8QA0US',
        image: Mezzaluna,
        price: 58,
    },
    {
        id: 3,
        name: 'La Gondola Homemade Pasta Cutter - Smooth Wheel',
        asin: 'B00GY5XMYG',
        image: SmoothWheel,
        price: 39,
    },
    {
        id: 4,
        name: 'La Gondola Homemade Ravioli Stamp - Mezzaluna Shape',
        asin: 'B01C8QA0US',
        image: Mezzaluna,
        price: 40,
    },
    {
        id: 5,
        name: 'La Gondola Homemade Pasta Cutter - Adjustable Wheel',
        asin: 'B07QN7HGLG',
        image: AdjustableWheel,
        price: 88,
    },
    {
        id: 6,
        name: 'La Gondola Homemade Pasta Cutter - Double Wheel',
        asin: 'B0065P2WB4',
        image: DoubleWheel,
        price: 72,
    },
];

const ProductCard: React.FC<Product & { selectedProductAsin: string }> = ({
                                                                              name,
                                                                              asin,
                                                                              image,
                                                                              price,
                                                                              selectedProductAsin
                                                                          }) => {
    const amazonLink = `https://www.amazon.co.uk/gp/aws/cart/add.html?ASIN.1=${selectedProductAsin}&ASIN.2=${asin}`;
    const discountedPrice = (price * 0.85).toFixed(2);
    const amazonProductLink = `https://www.amazon.co.uk/dp/${asin}`;

    return (
        <div
            className="group my-10 flex w-full max-w-xs flex-col overflow-hidden rounded-lg border border-gray-100 bg-white shadow-md">
            <a className="relative mx-3 mt-3 flex h-60 overflow-hidden rounded-xl" href={amazonLink} target="_blank"
               rel="noopener noreferrer">
                <img className="absolute top-0 right-0 h-full w-full object-contain" src={image} alt="product image"/>
            </a>
            <div className="mt-4 px-5 pb-5">
                <a href={amazonLink} target="_blank" rel="noopener noreferrer">
                    <h5 className="text-xl tracking-tight text-slate-900">{name}</h5>
                </a>
                <div className="mt-2 mb-5 flex items-center justify-between">
                    <p>
                        <span className="text-xl font-bold text-slate-900 line-through">£{price.toFixed(2)}</span>
                        <span className="text-2xl font-bold text-green-500 ml-2">£{discountedPrice}</span>
                    </p>
                </div>
                <div className="flex w-full items-center space-x-2">
                    <a
                        href={amazonLink}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="flex w-full items-center justify-center rounded-md bg-slate-900 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-gray-700 focus:outline-none focus:ring-4 focus:ring-blue-300"
                    >
                       <ShoppingCart className="mr-2"/>
                        Buy Now
                    </a>
                    <a
                        href={amazonProductLink}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="flex items-center justify-center rounded-md bg-gray-200 p-2 text-gray-700 hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-400"
                    >
                        <EyeIcon />
                    </a>
                </div>
            </div>
        </div>
    );
};

interface ComplimentsProps {
    currentSlide: number;
    selectedProductAsin: string;
}

const Compliments: React.FC<ComplimentsProps> = ({currentSlide, selectedProductAsin}) => {
    const displayedProducts = currentSlide === 0
        ? products.slice(0, 3)
        : products.slice(3, 6);

    return (
        <div className="container mx-auto px-4">
            <div className="flex flex-wrap justify-around">
                {displayedProducts.map((product) => (
                    <ProductCard key={product.id} {...product} selectedProductAsin={selectedProductAsin}/>
                ))}
            </div>
        </div>
    );
};

export default Compliments;
