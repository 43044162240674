import React, { useState } from 'react';
import Hero from "../layout/Hero";
import Header from "../layout/Header";
import Compliments from "../components/Compliments";
import Logo from "../assets/pastaline_logo.png";
import Pastaline from '../assets/pastaline.png';

const Home = () => {
    const [currentSlide, setCurrentSlide] = useState(0);
    const slides = [
        {
            title: 'PASTALINE',
            subtitle: 'Premium Hand Crank Dough Sheeter Made In Italy',
            priceOld: '£515.00',
            priceNew: '£489.05',
            buttonText: 'Buy Now',
            image: Pastaline,
            imageAlt: 'PASTALINE',
            asin: 'B00OD10CRI'
        }
    ];

    const handleSlideChange = (index: number) => {
        setCurrentSlide(index);
    };

    const selectedProductAsin = slides[currentSlide].asin;

    return (
        <div>
            <Header/>
            <Hero onSlideChange={handleSlideChange}/>
            <div className="mx-auto pt-10 font-bold text-xl md:text-2xl text-center">
                <p>Bundle and Save: Enjoy <span className="text-red-500">15% OFF</span> Your Second Product!</p>
            </div>
            <Compliments currentSlide={currentSlide} selectedProductAsin={selectedProductAsin}/>
            <footer className="relative mt-20 bg-gray-100 px-4 pt-20">
                <div>
                    <img src={Logo} alt="Pastaline" className="w-32 md:w-40 mx-auto"/>
                </div>
                <p className="py-10 text-center text-sm md:text-base">© 2024 | All Rights Reserved</p>
            </footer>
        </div>
    );
};

export default Home;
