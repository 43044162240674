import React from 'react';
import Slider from 'react-slick';
import Pastaline from '../assets/pastaline.png';
import AmazonLogo from '../assets/amazon_logo.png';
import { CheckCircle } from "lucide-react";

interface Slide {
    title: string;
    subtitle: string;
    priceOld: string;
    priceNew: string;
    buttonText: string;
    image: string;
    imageAlt: string;
    asin: string;
    bulletPoints: string[];
    attributionLink: string;
}

const slides: Slide[] = [
    {
        title: 'PASTALINE',
        subtitle: 'Premium Hand Crank Dough Sheeter Made In Italy',
        priceOld: '£515.00',
        priceNew: '£489.05',
        buttonText: 'Buy Now',
        image: Pastaline,
        imageAlt: 'PASTALINE',
        asin: 'B00OD10CRI',
        bulletPoints: [
            'Advanced cake decorating made easy',
            'Ultra-thin ribbons with professional precision',
            'Effortless color blending for vibrant results'
        ],
        attributionLink: 'https://www.amazon.co.uk/dp/B00OD10CRI?maas=maas_adg_CCD5601B34FA35EB70A0729015AEA8D9_afap_abs&ref_=aa_maas&tag=maas'
    }
];

export default function Hero({ onSlideChange }: { onSlideChange: (index: number) => void }) {
    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        afterChange: onSlideChange,
    };

    return (
        <section className="relative bg-gray-100">
            <div className="container mx-auto px-4 py-8">
                <Slider {...settings}>
                    {slides.map((slide, index) => (
                        <div key={index} className="relative flex flex-col md:flex-row items-center min-h-[60vh] md:min-h-[70vh] lg:min-h-[80vh]">
                            <div className="flex flex-col max-w-2xl items-start pt-4 md:pt-10 lg:pt-20 space-y-2 md:space-y-4 lg:space-y-5 w-full md:w-1/2">
                                <h1 className="text-3xl sm:text-4xl md:text-5xl lg:text-6xl xl:text-7xl font-bold">
                                    {slide.title} <span className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl">®</span>
                                </h1>
                                <p className="text-sm sm:text-base md:text-lg lg:text-xl">{slide.subtitle}</p>
                                <ul className="space-y-2 md:space-y-3">
                                    {slide.bulletPoints.map((point, i) => (
                                        <li key={i} className="flex items-center space-x-2">
                                            <CheckCircle className="text-cyan-500 w-4 h-4 sm:w-5 sm:h-5 flex-shrink-0"/>
                                            <span className="text-xs sm:text-sm md:text-base lg:text-lg">{point}</span>
                                        </li>
                                    ))}
                                </ul>
                                <div className="text-xs sm:text-sm md:text-base lg:text-lg">
                                    <span className="font-bold text-cyan-500 text-base sm:text-lg md:text-2xl lg:text-3xl">{slide.priceOld}</span>{' '}
                                    {/*<span className="font-bold text-cyan-500 text-base sm:text-lg md:text-xl lg:text-2xl">{slide.priceNew}</span>*/}
                                </div>
                                {/*<a href={slide.attributionLink} target="_blank" rel="noopener noreferrer" className="w-full sm:w-auto">*/}
                                {/*    <button className="bg-cyan-500 text-white px-4 md:px-6 py-2 md:py-3 text-sm md:text-base lg:text-lg w-full sm:w-auto">*/}
                                {/*        {slide.buttonText}*/}
                                {/*    </button>*/}
                                {/*</a>*/}
                            </div>
                            <div className="w-full md:w-1/2 mt-8 md:mt-0 md:absolute md:right-0 md:top-1/2 md:transform md:-translate-y-1/2">
                                <img
                                    src={slide.image}
                                    alt={slide.imageAlt}
                                    className="w-full h-auto max-h-[30vh] sm:max-h-[40vh] md:max-h-[50vh] lg:max-h-[60vh] object-contain mx-auto"
                                />
                            </div>
                            <div className="absolute top-0 right-0 mt-4 md:mt-8 mr-4 md:mr-8 bg-gray-800 rounded-full flex flex-col text-white gap-1 md:gap-2 p-2 md:p-4">
                                <div>
                                    <img src={AmazonLogo} alt="Amazon Logo" className="h-6 sm:h-8 md:h-10 lg:h-12 pt-1 mx-auto"/>
                                </div>
                            </div>
                        </div>
                    ))}
                </Slider>
            </div>
        </section>
    );
}