import nReact from 'react';
import Logo from '../assets/pastaline_logo.png';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBookOpen } from "@fortawesome/free-solid-svg-icons";

const Header: React.FC = () => {
  return (
      <header className="bg-gray-100">
        <div
            className="container mx-auto px-4 flex flex-col md:flex-row justify-between items-center border-b border-gray-300">
          <div className="text-2xl font-bold px-4 flex items-center mb-4 md:mb-0">
            <img src={Logo} alt="Pastaline" className="w-32 md:w-40"/>
          </div>
          <nav className="flex-1 text-center font-medium text-sm mb-4 md:mb-0">
            Bundle & Save 15% OFF Your Second Product!
          </nav>
          <div className="flex items-center">
            <a href="https://www.amazon.co.uk/stores/Pastaline/page/4C725797-DAED-4599-AE12-7372969AE537?ref_=ast_bln"
               target="_blank"
               className="text-gray-700 hover:text-black border-l border-gray-300 flex items-center px-4 py-6">
              <FontAwesomeIcon icon={faBookOpen} className="mr-2"/> Products
            </a>
          </div>
        </div>
      </header>
  );
};

export default Header;
